import React from 'react';

import Card from '@/components/case-studies/card';

const OtherCaseStudyPosts = ({ title, posts, cardLinkLabel }) => {
  return (
    <div className="mx-auto max-w-[90rem] px-5 py-6 md:py-10 lg:py-14">
      <h3 className="text-3xl font-medium leading-tight text-[#0C1930] md:text-4xl">
        {title}
      </h3>
      <div className="grid grid-cols-1 gap-4 py-6 sm:grid-cols-2 lg:gap-8 lg:pt-16">
        {posts.map((post) => (
          <Card
            item={post}
            cardLinkLabel={cardLinkLabel}
            key={post.slug.current}
            cardClassName="md:!h-[350px]"
          />
        ))}
      </div>
    </div>
  );
};

export default OtherCaseStudyPosts;
