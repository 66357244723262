import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const CaseStudyPostHero = ({ data }) => {
  const {
    title,
    mainImage,
    clientLogo,
    mainImageAlt,
    clientSummary,
    heroColor,
  } = data;

  const gradientColorClass = `bg-gradient-to-t from-${heroColor}/70 to-transparent`;

  return (
    <div className="bg-white px-5 py-6 md:py-10 lg:py-20">
      <section className="mx-auto h-[460px] max-w-[90rem] overflow-hidden md:my-10 md:h-[500px] lg:h-[570px]">
        {/* inner */}
        <div className="relative h-full w-full overflow-hidden rounded-2xl">
          {/* main image */}
          <div className="absolute inset-0 z-0">
            <GatsbyImage
              image={mainImage.asset.gatsbyImageData}
              alt={mainImageAlt}
              imgClassName="h-full w-full object-cover"
              className="h-full w-full max-w-none"
            />
            {/* subtle overlay */}
            <div className="absolute inset-0 z-10 bg-black/10"></div>
          </div>
          {/* logo image */}
          <img
            src={clientLogo.asset.url}
            alt={mainImageAlt}
            className="absolute left-6 top-6 z-20 h-9 w-auto"
          />

          {/* content */}
          <div
            className={`absolute bottom-0 z-10 flex w-full flex-col justify-end ${gradientColorClass} px-4 sm:px-5`}
          >
            {/* inner */}
            <div className="lg:w-[70%]">
              {/* title */}
              <h1 className="text-3xl leading-tight text-white sm:text-4xl md:block md:text-5xl lg:text-[3.5rem]">
                {title}
              </h1>

              {/* summary */}
              <div className="mb-2 mt-2 flex flex-row gap-3 border-t border-t-white/30 py-1 md:mt-8 md:gap-8 md:py-3">
                {clientSummary.map((item) => (
                  <div className="flex flex-col gap-[2px] py-1 text-sm text-white sm:text-base md:py-2">
                    <p className="font-bold uppercase">{item.title}</p>
                    <p className="font-normal opacity-70">{item.subtitle}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudyPostHero;
